import { graphql } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { helmetJsonLdProp } from 'react-schemaorg';
import Hero, { HeroData } from 'components/Hero';
import Layout from 'components/Layout';
import { CsBlock, mapBlocks } from 'helpers/blockMapper';
import { processPageMeta } from 'helpers/csTypeProcessors';
import { useAnalyticsPageVariables } from 'helpers/useAnalyticsPageVariables';
import { PageMeta } from 'types/contentStack';

type InfoPageProps = {
  data: InfoPageData;
};

const InfoPage: React.FC<InfoPageProps> = ({ data }) => {
  const page = data.csInfoPage;
  const { meta, breadcrumbs } = processPageMeta(page.page_meta, page.url);
  const jsonLdIsEmpty = page.json_ld === '' || page.json_ld === '{}';
  useAnalyticsPageVariables(
    {
      pageCategories: breadcrumbs.parent_links.map((link) => link.page_name),
      pageType: 'InfoPage',
    },
    page.blocks
  );

  return (
    <>
      {!jsonLdIsEmpty && <Helmet script={[helmetJsonLdProp(JSON.parse(page.json_ld))]} />}
      <Layout
        pageType="brochureware"
        meta={meta}
        breadcrumbs={breadcrumbs}
        complianceText={page.footer_compliance ?? undefined}
        showStickyHeader={page.show_sticky_header ?? undefined}
        showAlertNotification={page.show_alert_notification ?? undefined}>
        <Hero hero={page.hero} />
        {page.blocks && mapBlocks(page.blocks)}
      </Layout>
    </>
  );
};
export default InfoPage;

type InfoPageData = {
  csInfoPage: {
    title: string;
    url: string;
    hero: HeroData;
    blocks: CsBlock[];
    page_meta: PageMeta;
    json_ld: string;
    footer_compliance: string | null;
    show_sticky_header: boolean | null;
    show_alert_notification: boolean | null;
  };
};

export const query = graphql`
  query($id: String!) {
    csInfoPage(id: { eq: $id }) {
      title
      url
      ...HeroInfo
      footer_compliance
      show_sticky_header
      show_alert_notification
      blocks {
        ...InfoCardsBlockInfo
        ...InfoCardsHomePageBlockInfo
        ...RichTextBlockInfo
        ...IntroBlockInfo
        ...FeaturesBlockInfo
        ...SplitInfoCardBlockInfo
        ...DividerBlockInfo
        ...DocumentDownloadBlockInfo
        ...RelatedContentBlockInfo
        ...TableWithIconsBlockInfo
        ...ChecklistSectionBlockInfo
        ...CtaBannerBlockInfo
        ...TestimonialsBlockInfo
        ...TabbedTableSectionBlockInfo
        ...BasicAccordionBlockInfo
        ...NestedAccordionBlockInfo
        ...ImageAndCtaCardsBlockInfo
        ...CookiesListBlockInfo
      }
      page_meta {
        ...BreadcrumbsInfo
        ...MetaDataTagsInfo
      }
      json_ld
    }
  }
`;
